/* eslint-disable */
// ----------------------------------------------------------------------

export default function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
export const removeLicence = async () => {
    const element = document.querySelector('.MuiDataGrid-virtualScroller')
    if (element && element.nextSibling && element.nextSibling.style) {
        element.nextSibling.style.display = 'none'
    }
}