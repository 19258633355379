/* eslint-disable import/no-anonymous-default-export */
import client from './client'

// Sheet operations
const createSheet = (tableId, payload) => client.post(`/sheets/${tableId}/create`, payload)
export const getSheet = (tableId) => client.get(`/sheets/${tableId}`)
const getSheets = (tableId, params) => client.get(`/sheets/${tableId}/list`, { 
    params: {
        ...params,
        limit: 20 // Get first 20 rows
    }
})
const getSheetRows = (sheetId, params = { page: 1, limit: 20 }) => client.get(`/sheets/${sheetId}/rows`, {
    params: {
        page: params.page || 1,
        limit: params.limit || 20
    }
})
const deleteSheet = (sheetId) => client.delete(`/sheets/${sheetId}`)
const getTableBatchStats = (tableId) => client.get(`/batches/stats/${tableId}`)
const resetTableBatch = (tableId) => client.delete(`/batches/stats/${tableId}`)

export default {
    createSheet,
    getSheets,
    deleteSheet,
    getSheet,
    getSheetRows,
    getTableBatchStats,
    resetTableBatch,
    clearRedisCache: () => client.delete(`/batches/cache`)
}
