
const initialState = {
    openChat: false,
    messages: [
        {
            message: 'Hello, how can I help you?',
            sender: 'bot'
        }
    ],
    isMsgSending: false
}
const chatReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_MSG_SENDING':
            return {
                ...state,
                isMsgSending: payload
            }
        case 'SET_MESSAGES_DG':
            return {
                ...state,
                messages: payload
            }
        case 'APPEND_OLD_MESSAGES':
            return {
                ...state,
                messages: [...payload, ...state.messages]
            }
        case 'ADD_MESSAGE_DG':
            return {
                ...state,
                messages: [...state.messages, payload]
            }
        case 'CLOSE_CHAT_DG':
            return {
                ...state,
                openChat: false
            }
        case 'START_CHAT_DG':
            return {
                ...state,
                openChat: true
            }
        default:
            return state
    }
}
export default chatReducer
