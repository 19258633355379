/* eslint-disable import/no-anonymous-default-export */
import client from './client'
export default {
    getApps: () => client.get(`/integrations`),
    getApp: (id) => client.get(`/integrations/${id}`),
    saveApp: (payload) => client.post(`/integrations`, payload),
    updateApp: (id, payload) => client.put(`/integrations/${id}`, payload),
    deleteApp: (id) => client.delete(`/integrations/${id}`),
    getAppConfig: (id, connectionId) => client.get(`/integrations/${id}/config/${connectionId}`),
    importData: (id, connectionId, payload) => client.post(`/integrations/${id}/config/${connectionId}`, payload),
    runQuery: (payload) => client.post(`/integrations/runQuery`, payload),
    startScrape: (id, payload) => client.post(`/integrations/${id}/scrape`, payload),
    checkForColumns: (id, payload) => client.post(`/integrations/${id}/columns`, payload),
    generateCell: (id, payload) => client.post(`/integrations/${id}/generateCell`, payload),
    sendMessage: (id, payload) => client.post(`/integrations/${id}/sendMessage`, payload),
    getMessages: (id) => client.get(`/integrations/${id}/getMessages`),
    search: (payload) => client.post(`/integrations/search`, payload),
    generateFormula: (id, payload) => client.post(`/integrations/${id}/generateFormula`, payload),
    scrapeNew: (payload) => client.post(`/integrations/scrapeNew`, payload),
}
