/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { HomeIcon, ChartPieIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import clsx from 'clsx'
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined'
import { TableCellsIcon } from '@heroicons/react/24/outline'
let adminmenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableCellsIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: TableCellsIcon, current: true },
    {
        name: 'Tools',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Tool list', href: '/dashboard/tools' },
            { name: 'New Tool', href: '/dashboard/tools/new' }
        ]
    },
    {
        name: 'Providers',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Provider list', href: '/dashboard/provider' },
            { name: 'New provider', href: '/dashboard/provider/new' }
        ]
    },
    {
        name: 'Categories',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Category list', href: '/dashboard/category' },
            { name: 'New Category', href: '/dashboard/category/new' }
        ]
    },
    {
        name: 'Scrapes',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        children: [
            { name: 'Scrape list', href: '/dashboard/ap-scrape' },
            { name: 'New Scrape', href: '/dashboard/ap-scrape/new' }
        ]
    },
    { name: 'Error Analytics', href: '/dashboard/error-analytics', icon: ChartPieIcon, current: false },
    { name: 'Batch Analytics', href: '/dashboard/batch/dashboard', icon: ChartPieIcon, current: false },
    { name: 'Plans', href: '/dashboard/plans', icon: ChartPieIcon, current: false },
    { name: 'Runs', href: '/dashboard/cell-runs', icon: ChartPieIcon, current: false },
    { name: 'Usage', href: '/dashboard/usage', icon: ChartPieIcon, current: false },
    { name: 'Users Report', href: '/dashboard/users', icon: ChartPieIcon, current: false },
    { name: 'Blogs', href: '/dashboard/blogs', icon: ChartPieIcon, current: false },
    { name: 'System Connections', href: '/dashboard/system-connections', icon: SettingsInputComponentOutlinedIcon, current: true }
]
let usermenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableCellsIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: TableCellsIcon, current: true },
    { name: 'Usage', href: '/dashboard/usage', icon: ChartPieIcon, current: false },
    {
        name: 'Tools',
        icon: DynamicFeedOutlinedIcon,
        current: false,
        href: '/dashboard/tools'
    },
]
const chainermenus = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'My Tables', href: '/dashboard/tables', icon: TableCellsIcon, current: true },
    { name: 'Workspaces', href: '/dashboard/workspaces', icon: TableCellsIcon, current: true },
    { name: 'Usage', href: '/dashboard/usage', icon: ChartPieIcon, current: false }
]
const navigation2 = [
    { name: 'Connections', href: '/dashboard/connections', icon: SettingsInputComponentOutlinedIcon, current: true },
    {
        name: 'Upgrade',
        icon: SettingsInputComponentOutlinedIcon,
        current: false,
        href: '/dashboard/upgrade'
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function NavItem({ item }) {
    const location = useLocation()
    const isCurrent = (href, hasSubMenus) => location.pathname === href || (hasSubMenus && location.pathname.includes(href))

    const isCurrent2 = (href, exact = false) => {
        return exact ? location.pathname === href : (location.pathname ?? '').startsWith(href)
    }
    const isChildVisited = (item?.children ?? []).some((subItem) => isCurrent2(subItem.href))
    return (
        <li key={item.name}>
            {!item.children ? (
                <Link
                    to={item.href}
                    className={classNames(
                        isCurrent(item.href) ? 'bg-mtlite text-mtmain font-semibold' : '',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6  hover:bg-gray-100 hover:bg-gray-600/5  text-gray-700 hover:text-gray-900 '
                    )}
                >
                    <item.icon
                        aria-hidden='true'
                        className={classNames(
                            isCurrent(item.href) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                            'h-6 w-6 shrink-0 text-gray-400 hover:bg-gray-100'
                        )}
                    />
                    {item.name}
                </Link>
            ) : (
                <Disclosure as='div' key={item.name} defaultOpen={isChildVisited}>
                    {({ open }) => (
                        <>
                            <DisclosureButton
                                className={clsx(
                                    isCurrent2(item.href, true) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                                    'group flex w-full items-center gap-x-3 rounded-md p-2 text-left hover:bg-gray-100 hover:bg-gray-600/5 text-gray-700 hover:text-gray-900 '
                                )}
                            >
                                <item.icon aria-hidden='true' className='h-6 w-6 shrink-0 text-gray-400' />
                                {item.name}
                                <ChevronRightIcon
                                    className={clsx(
                                        'ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500',
                                        open && 'rotate-180'
                                    )}
                                />
                            </DisclosureButton>
                            <DisclosurePanel as='ul' className='mt-1 px-2' open={open}>
                                {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                        <Link
                                            to={subItem.href}
                                            className={clsx(
                                                isCurrent2(subItem.href) ? 'bg-mtlite font-semibold text-mtmain ' : '',
                                                'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-100'
                                            )}
                                        >
                                            {subItem.name}
                                        </Link>
                                    </li>
                                ))}
                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>
            )}
        </li>
    )
}
export default function Sidebar() {
    const { user } = useAuth()

    let navigation = []
    if (user?.role === 'admin') {
        navigation = adminmenus
    } else if (user?.role === 'user') {
        navigation = usermenus
    } else if (user?.role === 'chainer') {
        navigation = chainermenus
    }
    return (
        <div
            className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200  px-6'
            style={{
                backgroundImage: 'linear-gradient(white 4%, rgb(250, 250, 250, 0.5) 100%)'
            }}
        >
            <div className='flex h-16 shrink-0 items-center'>
                <img alt='Meerkats.ai' src='/logo/logo.svg' className='h-8 w-auto' />
            </div>
            <nav className='flex flex-1 flex-col'>
                <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                    <li>
                        <ul role='list' className='-mx-2 space-y-1'>
                            {navigation.map((item) => (
                                <NavItem item={item} key={item.href} />
                            ))}
                        </ul>
                    </li>
                    <li className='mx-2 mt-auto'>
                        <ul role='list' className='-mx-2 space-y-1'>
                            {navigation2.map((item) => (
                                <NavItem item={item} key={item.href} />
                            ))}
                        </ul>
                        <li className='-mx-6 mt-auto'>
                            <Link
                                to='/dashboard/user/account'
                                className='flex items-center gap-x-2 px-6 py-3 text-sm  text-gray-700 hover:bg-gray-100'
                            >
                                <span className='flex size-6 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white text-xs text-gray-700 '>
                                    <span className='text-xs font-medium leading-none text-gray-700'>{user?.name?.split('')[0]}</span>
                                </span>

                                <span className='sr-only'>Your profile</span>
                                <span aria-hidden='true'>{user?.name}</span>
                            </Link>
                        </li>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
