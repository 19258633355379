import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { removeSnackbar } from 'store/actions'

import useResponsive from '../hooks/useResponsive'
let displayed = []

const useNotifier = () => {
    const dispatch = useDispatch()
    const isDesktop = useResponsive('up', 'lg')
    const notifier = useSelector((state) => state.notifier)
    const { notifications } = notifier

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = (id) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)]
    }
    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key)
                return
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                variant: options.variant || 'default',
                persist: false,
                anchorOrigin: {
                    vertical: isDesktop ? 'bottom' : 'top',
                    horizontal: isDesktop ? 'left' : 'left'
                },
                autoHideDuration: options.variant === 'error' || options.persist ? 5000 : 2000,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey)
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey))
                    removeDisplayed(myKey)
                }
            })

            // keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])
}

export default useNotifier
